<template lang="pug">
.filters
  .row.justify-content-between.m-t-5.m-b-15
    .col-md-9.col-sm-6.col-xs-12
      .d-flex.align-items-center
        AppAddNewButton(
          title="ota_management.add_company"
          @click="$emit('add-new-company')"
        )
    .col-md-3.col-sm-6.col-xs-12.text-right
      AppDownloadButton(disabled)
</template>

<script>
  export default {
    components: {
      AppDownloadButton: () => import("@/components/elements/AppButton/WithIcon/Download"),
      AppAddNewButton: () => import("@/components/elements/AppButton/WithIcon/AddNew")
    }
  }
</script>

<style lang="sass" scoped></style>
